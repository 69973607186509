var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"visible":_vm.isDialog,"append-to-body":"","width":_vm.width + '%',"before-close":_vm.cancellationFn},on:{"update:visible":function($event){_vm.isDialog=$event}}},[_c('div',{staticStyle:{"color":"#409eff"},attrs:{"slot":"title"},slot:"title"},[_vm._v("项目绩效调整变更记录")]),_c('div',{staticClass:"content",attrs:{"name":"content"}},[_c('el-descriptions',{staticStyle:{"margin-bottom":"10px"},attrs:{"title":"项目基础信息","direction":"vertical","column":7,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"项目名称","span":3}},[_vm._v(" "+_vm._s(_vm.projectInformation.projectName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"项目来源","span":1}},[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.projectInformation.projectSource,_vm.dictData.projectSource))+" ")]),_c('el-descriptions-item',{attrs:{"label":"业务种类","span":1}},[_vm._v(" "+_vm._s(_vm._f("dict")(_vm.projectInformation.businessLines,_vm.dictData.businessLines))+" ")]),_c('el-descriptions-item',{attrs:{"label":"部门","span":1}},[_vm._v(" "+_vm._s(_vm.projectInformation.theirDeptName)+" ")]),_c('el-descriptions-item',{attrs:{"label":"项目经理","span":1}},[_vm._v(" "+_vm._s(_vm.projectInformation.managerName)+" ")])],1),_c('div',{staticClass:"content-maincon"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"content-text",staticStyle:{"color":"blue"}},[_vm._v("更改前")]),_c('el-descriptions',{attrs:{"title":"项目费用结算","direction":"vertical","column":4,"border":""}},[_c('template',{slot:"extra"},[_c('span',{staticStyle:{"font-size":"17px","color":"gray"}},[_vm._v("单位：元")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('actualAmount')
                  ? { 'background-color': '#bff0de' }
                  : '',"label":"项目实际金额","span":1}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.actualAmount)))])]),_c('el-descriptions-item',{attrs:{"span":1,"label":"税费"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.taxesFee)))])]),_c('el-descriptions-item',{attrs:{"label":"招标费","span":1}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.tenderFee)))])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('disposableProjectExpense')
                  ? { 'background-color': '#bff0de' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_c('div',[_vm._v("项目可支配费用")]),_c('div',{staticStyle:{"font-size":"12px","color":"red","margin-top":"10px"}},[_vm._v(" 项目可支配费用 = 项目实际金额 - 税费 - 招投标费用 - 预计外部协作费用 ")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.disposableProjectExpense)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('productionCost')
                  ? { 'background-color': '#bff0de' }
                  : '',"span":2}},[_c('template',{slot:"label"},[_c('div',[_vm._v("内部生产费用 =")]),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('span',{staticStyle:{"font-size":"12px","color":"red"}},[_vm._v(" 内部生产费用 = 项目可支配费用 X 生产费用比例 ")])])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.productionCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('deptManagementPerformance')
                  ? { 'background-color': '#bff0de' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（1） 部门管理绩效")]),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('span',{staticStyle:{"font-size":"12px","color":"red"}},[_vm._v(" 部门管理绩效 = 内部生产费用 X 25% ")])])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.deptManagementPerformance)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('sumExpenseCost')
                  ? { 'background-color': '#bff0de' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（2） 报销费用合计")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.sumExpenseCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('sumStaffCost')
                  ? { 'background-color': '#bff0de' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（3） 人员成本合计")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.sumStaffCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('othersStaffCost')
                  ? { 'background-color': '#bff0de' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（4） 其它人员成本")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.othersStaffCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('outsourceSumCost')
                  ? { 'background-color': '#bff0de' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（5） 外协生产费用合计")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.outsourceSumCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('depreciationEquipmentFee')
                  ? { 'background-color': '#bff0de' }
                  : '',"label":"（6） 设备折旧"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.depreciationEquipmentFee)))])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('corporateProfit')
                  ? { 'background-color': '#bff0de' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_vm._v(" 公司利润 ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.corporateProfit)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('productPerformance')
                  ? { 'background-color': '#bff0de' }
                  : '',"span":2}},[_c('template',{slot:"label"},[_c('div',[_vm._v(" 生产绩效 "),_c('span',{staticStyle:{"font-size":"12px","color":"red","margin-left":"20px"}},[_vm._v(" 生产绩效 = 内部生产费用 - 部门管理绩效 - 报销费用合计 - 人员成本合计 - 其它人员成本 - 外协生产费用合计 - 设备折旧 ")])])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.productPerformance)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('actualProductPerformance')
                  ? { 'background-color': '#bff0de' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_vm._v(" 实际生产绩效 ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.oldData.actualProductPerformance)))])],2)],2),_c('el-descriptions',{staticStyle:{"margin":"10px 0px"},attrs:{"title":"公司奖惩信息","direction":"vertical","column":1,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"团队奖惩","span":1}},[_c('div',{staticClass:"rp-inline"},[_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖惩金额：")]),_c('span',{class:_vm.form.changeFieldList.includes('teamSumAmount')
                        ? 'rp-inline-money-input left-input-bgc'
                        : 'rp-inline-money-input'},[_vm._v(_vm._s(_vm.form.oldData.teamSumAmount))])]),_c('div',{staticClass:"symbol"},[_vm._v("=")]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖励金额")]),_c('span',{class:_vm.form.changeFieldList.includes('teamBonusAmount')
                        ? 'rp-inline-money-input left-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"},attrs:{"placeholder":"请输入金额"}},[_vm._v(_vm._s(_vm.form.oldData.teamBonusAmount))])]),_c('div',{staticClass:"symbol"},[_c('div',{staticStyle:{"width":"8px","border-bottom":"1.5px solid #000","margin":"20px"}})]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("惩罚金额")]),_c('span',{class:_vm.form.changeFieldList.includes('teamPenaltyAmount')
                        ? 'rp-inline-money-input left-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"},attrs:{"placeholder":"请输入金额"}},[_vm._v(_vm._s(_vm.form.oldData.teamPenaltyAmount))])])])]),_c('el-descriptions-item',{attrs:{"span":1,"contentStyle":_vm.form.changeFieldList.includes('teamAmountRemark')
                  ? { 'background-color': '#bff0de' }
                  : ''}},[_c('template',{slot:"label"},[_vm._v(" 金额备注 ")]),_vm._v(" "+_vm._s(_vm.form.oldData.teamAmountRemark)+" ")],2),_c('el-descriptions-item',{attrs:{"label":"个人奖惩","span":1}},[_c('div',{staticClass:"rp-inline"},[_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖惩金额：")]),_c('span',{class:_vm.form.changeFieldList.includes('staffSumAmount')
                        ? 'rp-inline-money-input left-input-bgc'
                        : 'rp-inline-money-input'},[_vm._v(_vm._s(_vm.form.oldData.staffSumAmount))])]),_c('div',{staticClass:"symbol"},[_vm._v("=")]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖励金额")]),_c('span',{class:_vm.form.changeFieldList.includes('staffBonusAmount')
                        ? 'rp-inline-money-input left-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm.form.oldData.staffBonusAmount))])]),_c('div',{staticClass:"symbol"},[_c('div',{staticStyle:{"width":"8px","border-bottom":"1.5px solid #000","margin":"20px"}})]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("惩罚金额")]),_c('span',{class:_vm.form.changeFieldList.includes('staffPenaltyAmount')
                        ? 'rp-inline-money-input left-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm.form.oldData.staffPenaltyAmount))])])])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('staffAmountRemark')
                  ? { 'background-color': '#bff0de' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_vm._v(" 金额备注 ")]),_vm._v(" "+_vm._s(_vm.form.oldData.staffAmountRemark))],2)],1)],1),_c('div',[_c('div',{staticStyle:{"height":"60px"}}),_c('div',{staticClass:"vertical-dashed-line"})]),_c('div',{staticClass:"content-right",staticStyle:{"color":"red"}},[_c('div',{staticClass:"content-right-view"},[_c('div',{staticClass:"content-text",staticStyle:{}},[_vm._v("更改后")])]),_c('el-descriptions',{attrs:{"title":"项目费用结算","direction":"vertical","column":4,"border":""}},[_c('template',{slot:"extra"},[_c('span',{staticStyle:{"font-size":"17px","color":"gray"}},[_vm._v("单位：元")])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('actualAmount')
                  ? { 'background-color': '#eef9b2' }
                  : '',"label":"项目实际金额","span":1}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.actualAmount)))])]),_c('el-descriptions-item',{attrs:{"span":1,"label":"税费"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.taxesFee)))])]),_c('el-descriptions-item',{attrs:{"label":"招标费","span":1}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.tenderFee)))])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('disposableProjectExpense')
                  ? { 'background-color': '#eef9b2' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_c('div',[_vm._v("项目可支配费用")]),_c('div',{staticStyle:{"font-size":"12px","color":"red","margin-top":"10px"}},[_vm._v(" 项目可支配费用 = 项目实际金额 - 税费 - 招投标费用 - 预计外部协作费用 ")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.disposableProjectExpense)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('productionCost')
                  ? { 'background-color': '#eef9b2' }
                  : '',"span":2}},[_c('template',{slot:"label"},[_c('div',[_vm._v("内部生产费用 =")]),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('span',{staticStyle:{"font-size":"12px","color":"red"}},[_vm._v(" 内部生产费用 = 项目可支配费用 X 生产费用比例 ")])])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.productionCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('deptManagementPerformance')
                  ? { 'background-color': '#eef9b2' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（1） 部门管理绩效")]),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('span',{staticStyle:{"font-size":"12px","color":"red"}},[_vm._v(" 部门管理绩效 = 内部生产费用 X 25% ")])])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.deptManagementPerformance)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('sumExpenseCost')
                  ? { 'background-color': '#eef9b2' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（2） 报销费用合计")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.sumExpenseCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('sumStaffCost')
                  ? { 'background-color': '#eef9b2' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（3） 人员成本合计")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.sumStaffCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('othersStaffCost')
                  ? { 'background-color': '#eef9b2' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（4） 其它人员成本")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.othersStaffCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('outsourceSumCost')
                  ? { 'background-color': '#eef9b2' }
                  : ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v("（5） 外协生产费用合计")])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.outsourceSumCost)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('depreciationEquipmentFee')
                  ? { 'background-color': '#eef9b2' }
                  : '',"label":"（6） 设备折旧"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.depreciationEquipmentFee)))])]),_c('el-descriptions-item',{attrs:{"span":1,"contentStyle":_vm.form.changeFieldList.includes('corporateProfit')
                  ? { 'background-color': '#eef9b2' }
                  : ''}},[_c('template',{slot:"label"},[_vm._v(" 公司利润 ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.corporateProfit)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('productPerformance')
                  ? { 'background-color': '#eef9b2' }
                  : '',"span":2}},[_c('template',{slot:"label"},[_c('div',[_vm._v(" 生产绩效 "),_c('span',{staticStyle:{"font-size":"12px","color":"red","margin-left":"20px"}},[_vm._v(" 生产绩效 = 内部生产费用 - 部门管理绩效 - 报销费用合计 - 人员成本合计 - 其它人员成本 - 外协生产费用合计 - 设备折旧 ")])])]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.productPerformance)))])],2),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('actualProductPerformance')
                  ? { 'background-color': '#eef9b2' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_vm._v(" 实际生产绩效 ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("thousands")(_vm.form.newData.actualProductPerformance)))])],2)],2),_c('el-descriptions',{staticStyle:{"margin":"10px 0px"},attrs:{"title":"公司奖惩信息","direction":"vertical","column":1,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"团队奖惩","span":1}},[_c('div',{staticClass:"rp-inline"},[_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖惩金额：")]),_c('span',{class:_vm.form.changeFieldList.includes('teamSumAmount')
                        ? 'rp-inline-money-input right-input-bgc'
                        : 'rp-inline-money-input'},[_vm._v(_vm._s(_vm.form.newData.teamSumAmount))])]),_c('div',{staticClass:"symbol"},[_vm._v("=")]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖励金额")]),_c('span',{class:_vm.form.changeFieldList.includes('teamBonusAmount')
                        ? 'rp-inline-money-input right-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"},attrs:{"placeholder":"请输入金额"}},[_vm._v(_vm._s(_vm.form.newData.teamBonusAmount))])]),_c('div',{staticClass:"symbol"},[_c('div',{staticStyle:{"width":"8px","border-bottom":"1.5px solid #000","margin":"20px"}})]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("惩罚金额")]),_c('span',{class:_vm.form.changeFieldList.includes('teamPenaltyAmount')
                        ? 'rp-inline-money-input right-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"},attrs:{"placeholder":"请输入金额"}},[_vm._v(_vm._s(_vm.form.newData.teamPenaltyAmount))])])])]),_c('el-descriptions-item',{attrs:{"span":1,"contentStyle":_vm.form.changeFieldList.includes('teamAmountRemark')
                  ? { 'background-color': '#eef9b2' }
                  : ''}},[_c('template',{slot:"label"},[_vm._v(" 金额备注 ")]),_vm._v(" "+_vm._s(_vm.form.newData.teamAmountRemark)+" ")],2),_c('el-descriptions-item',{attrs:{"label":"个人奖惩","span":1}},[_c('div',{staticClass:"rp-inline"},[_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖惩金额：")]),_c('span',{class:_vm.form.changeFieldList.includes('staffSumAmount')
                        ? 'rp-inline-money-input right-input-bgc'
                        : 'rp-inline-money-input'},[_vm._v(_vm._s(_vm.form.newData.staffSumAmount))])]),_c('div',{staticClass:"symbol"},[_vm._v("=")]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("奖励金额")]),_c('span',{class:_vm.form.changeFieldList.includes('staffBonusAmount')
                        ? 'rp-inline-money-input right-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm.form.newData.staffBonusAmount))])]),_c('div',{staticClass:"symbol"},[_c('div',{staticStyle:{"width":"8px","border-bottom":"1.5px solid #000","margin":"20px"}})]),_c('div',{staticClass:"rp-inline-money"},[_c('span',[_vm._v("惩罚金额")]),_c('span',{class:_vm.form.changeFieldList.includes('staffPenaltyAmount')
                        ? 'rp-inline-money-input right-input-bgc'
                        : 'rp-inline-money-input',staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm.form.newData.staffPenaltyAmount))])])])]),_c('el-descriptions-item',{attrs:{"contentStyle":_vm.form.changeFieldList.includes('staffAmountRemark')
                  ? { 'background-color': '#eef9b2' }
                  : '',"span":1}},[_c('template',{slot:"label"},[_vm._v(" 金额备注 ")]),_vm._v(" "+_vm._s(_vm.form.newData.staffAmountRemark))],2)],1)],1)])],1),_c('span',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"slot":"footer","name":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"min-width":"120px","margin":"0 25px"},attrs:{"type":"primary","plain":"","loading":_vm.loading},on:{"click":_vm.cancellationFn}},[_vm._v("返 回")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }